<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg visitor-management-add-visit-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "reception-desks",
              "add-new-visit"
            )
          }}
        </div>
        <template v-slot:right>
          <icon class="save-icon" icon="#cx-hea1-save" @click="submitForm" />
        </template>
      </top-header-menu-wrapper>
    </template>

    <VeeForm v-if="visit" id="visitForm" class="form" novalidate>
      <!-- Visit details -->
      <ul class="clebex-item-section">
        <!-- Date from - date to -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateFromParsed) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-calendar" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayDate(selectedDateToParsed) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- Hour from - hour to -->
        <li class="clebex-item-section-item time">
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(selectedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateTimePicker"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :rangeMarked="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
        <!-- host -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="!$route.name.includes('my-visitors')"
        >
          <router-link
            :to="{ name: 'r_reception-desk-add-visit-select-host' }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "host"
                )
              }}</label>
              <p class="text-label ellipsis">
                <template v-if="visit.host">
                  {{ selectedHost }}
                </template>
                <template v-else>
                  {{
                    displayLabelName(
                      "visitor-management",
                      "reception-desks",
                      "host"
                    )
                  }}
                </template>
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
        </li>
        <!-- notes -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="notes">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "notes"
                )
              }}</label>
              <Field
                name="notes"
                as="input"
                type="text"
                id="notesId"
                v-model="visit.notes"
              />
            </div>
          </div>
        </li>
      </ul>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapGetters, mapActions, mapState } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import SelectDateRange from "@/components/global/SelectDateRange";
import SelectTimePickerRange from "@/components/global/SelectTimePickerRange";
import PillMenuTabs from "@/components/global/PillMenuTabs";

export default {
  name: "AddEditVisitor",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange,
    SelectTimePickerRange,
    PillMenuTabs
  },
  data() {
    return {
      preselectedTab: "time-slot",
      pillTabs: ["date", "time-slot"],
      showDateTimePicker: true,
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: this.getInitTime(),
      selectedHourTo: this.getInitTime(1),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      customDuration: null,
      isVisitor: true,
      disabled: {}
    };
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchInitData();
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visitorManagement", ["receptionDeskInfo"]),
    ...mapState("visit", ["visit"]),
    ...mapState("user", ["userList", "userFormFields"]),
    receptionDeskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    },
    visitorId() {
      const { visitorId } = this.$route.params;
      return visitorId;
    },
    selectedHost() {
      if (
        this.visit.host &&
        this.visit.host.first_name &&
        this.visit.host.last_name
      ) {
        return `${this.visit.host.first_name} ${this.visit.host.last_name}`;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("visit", ["postVisit", "getVisits"]),
    ...mapActions("user", ["getCompanyUserList"]),
    getInitTime(offset = 0) {
      let minutes =
        (5 * Math.round(parseInt(DateTime.now().toFormat("mm")) / 5)) % 60;
      /**
       * Convert 5 to 05 (when current minute is lesst then `08`)
       * "en-US" is irrelevant, it can be undefined also
       */
      minutes = minutes.toLocaleString("en-US", {
        minimumIntegerDigits: 2
      });
      const hours = DateTime.now()
        .plus({ hours: offset })
        .toFormat("HH");

      return `${hours}:${minutes}`;
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].eventDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].eventDate;
      } else {
        this.selectedDateTo = this.selectedDateFrom;
        this.selectedDateToParsed = this.selectedDateFromParsed;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    fetchInitData() {
      this.getCompanyUserList().then(() => {
        this.$store.commit("visit/setVisit", {
          host:
            this.userList && this.userList.data && this.userList.data.length
              ? this.userList.data[0]
              : null,
          datetime_from: this.selectedDateFrom,
          datetime_to: this.selectedDateTo,
          notes: ""
        });
      });
    },
    submitForm() {
      const dateTimeFrom = `${DateTime.fromISO(
        this.selectedDateFromParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.selectedDateToParsed
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      const payload = {
        datetime_from: dateTimeFrom,
        datetime_to: dateTimeTo,
        host_id: this.visit.host.id,
        reception_desk_id: this.receptionDeskId,
        visitor_id: this.visitorId,
        notes: this.visit.notes
      };

      return this.postVisit(payload).then(response => {
        if (response) {
          this.getVisits({
            payload: {
              visitor_id: this.visitorId,
              reception_desk_id: this.receptionDeskId
            }
          }).then(() => {
            this.$router.push({
              name: this.backLinkName,
              params: this.$router.params
            });
          });
        }
      });
    },
    isDisabled(field) {
      return (
        !this.isVisitor && this.disabled[field] && this.disabled[field].length
      );
    }
  },
  beforeUnmount() {
    this.$store.commit("visit/setVisit", null, { root: true });
  }
};
</script>
